@import "colors";

.com-btn {
  padding-bottom: 10px !important;
  font-size: 16px;
  //min-width: 100% !important;
  border-radius: 5px;
  //padding: 10px 27px;
  position: relative;
  overflow: hidden;
  background-color: #293d60;
  color: white !important;
  text-transform: none !important;
  min-height: 44px;
  font-weight: normal !important;
  width: 100%!important;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 100px;
    -webkit-transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    -ms-transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
  }
  //&:hover {
  //  &::before {
  //    background: white;
  //    -webkit-transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
  //    -ms-transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
  //    transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 80deg);
  //  }
  //}
}

.reg-btn {
  background-color: $orange !important;
  color: white !important;
  border: none !important;
  //margin-bottom: 5px;
}

@media (max-width: 2400px) {
  .about {
    padding-left: 10vw;
    padding-right: 10vw;
    .round-img {
      width: 280px !important;
      height: 280px !important;
    }
    .first-col {
      top: 250px !important;
    }
    .second-col {
      height: 10%;
      right: -15vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 13px !important;
    }
  }
}

@media (max-width: 1960px) {
  .about {
    padding-left: 10vw;
    padding-right: 10vw;
    .round-img {
      padding-left: 0vh !important;
      width: 250px !important;
      height: 250px !important;
    }
    .first-col {
      padding-left: 10vw !important;
      top: 100px !important;
      padding-bottom: 15px;
    }
    .second-col {
      height: 10%;
      right: 5vw !important;
      top: 5vh !important;
    }
    .sp-number {
      font-size: 15px !important;
    }
    .name-row1 {
      font-size: 48px;
    }
    .row-2 {
      color: white;
    }
    .name-row2 {
      font-size: 18px;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 1800px) {
  .about {
    padding-left: 10vw;
    padding-right: 10vw;
    .round-img {
      padding-left: 0vh !important;
      width: 250px !important;
      height: 250px !important;
    }
    .first-col {
      height: 10%;
      padding-left: 15vh !important;
      //right: 10vw !important;
      // top: 250px !important;
      padding-bottom: 10px;
    }
    .second-col {
      height: 10%;
      right: 5vw !important;
      top: 5vh !important;
    }
    .sp-number {
      font-size: 15px !important;
    }
    .name-row1 {
      font-size: 40px !important;
    }
    .row-2 {
      color: white;
    }
    .name-row2 {
      font-size: 18px;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 1720px) {
  .about {
    padding-left: 10vw;
    padding-right: 10vw;
    .round-img {
      width: 250px !important;
      height: 250px !important;
    }
    .first-col {
      height: 10%;
      padding-left: 5vw !important;
      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      top: 8vh !important;
    }
    .sp-number {
      font-size: 13px !important;
    }
    .name-row1 {
      font-size: 40px !important;
    }
    .row-2 {
      color: white;
    }
    .name-row2 {
      font-size: 18px;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 1537px) {
  .about {
    padding-left: 10vw;
    padding-right: 10vw;
    .round-img {
      padding-left: 0vw !important;
      width: 250px !important;
      height: 250px !important;
    }
    .first-col {
      height: 10%;
      padding-left: 0vw !important;
      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      height: 10%;
      top: 8vh !important;
    }
    .sp-number {
      font-size: 13px !important;
    }
    .name-row1 {
      font-size: 40px !important;
    }
    .row-2 {
      color: white;
    }
    .name-row2 {
      font-size: 18px;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 1426px) {
  .about {
    padding-left: 9vw;
    padding-right: 9vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      padding-top: 10px !important;
      height: 10%;

      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      height: 10%;
      top: 8vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      font-size: 30px !important;
    }
    .row-2 {
      font-size: 24px !important;
      color: white;
    }
    .name-row2 {
      font-size: 16px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 1299px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 200px !important;
      height: 200px !important;
    }
    .first-col {
      //padding-left: 5vw !important;
      padding-top: 10px !important;
      height: 10%;
      padding-left: 0vw !important;
      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      height: 10%;
      top: 10vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      font-size: 30px !important;
    }
    .row-2 {
      font-size: 20px !important;
      color: white;
    }
    .name-row2 {
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {

      font-size: 20px;
    }
  }
}

@media (max-width: 1280px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      padding-top: 10px !important;
      height: 10%;
      right: 8vw !important;
      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      height: 10%;
      top: 8vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      font-size: 30px !important;
    }
    .row-2 {
      font-size: 20px !important;
      color: white;
    }
    .name-row2 {
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {

      font-size: 20px;
    }
  }
}

@media (max-width: 1200px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 200px !important;
      height: 200px !important;
    }
    .first-col {
      padding-top: 10px !important;
      height: 10%;
      right: 8vw !important;
      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      line-height: 0px !important;
      height: 10%;
      right: 10vw !important;
      top: 8vh !important;
    }
    .sp-number {
      line-height: 0px !important;
      font-size: 14px !important;
    }
    .name-row1 {
      font-size: 35px !important;
    }
    .row-2 {
      line-height: 2px !important;
      font-size: 30px !important;
      color: white;
    }
    .name-row2 {
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      font-size: 20px !important;
    }
  }
}

@media (max-width: 1024px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 200px !important;
      height: 200px !important;
    }
    .first-col {
      padding-top: 10px !important;
      height: 10%;
      right: 8vw !important;
      top: 100px !important;
      padding-bottom: 10px;
    }
    .second-col {
      line-height: 2px !important;
      height: 10%;
      right: 0vw !important;
      top: 5vh !important;
    }
    .sp-number {

      font-size: 11px !important;
      // padding-left: 70px!important;
    }
    .name-row1 {
      font-size: 30px !important;
    }
    .row-2 {
      font-size: 24px !important;
      color: white;
    }
    .name-row2 {
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
      font-size: 20px !important;
    }
  }
}

@media (max-width: 991px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      text-align: center !important;
      top: 5vh !important;
      padding-left: 10vw !important;
      // height: 10%;
      //right: 10vw;
      //top: 290px !important;

    }
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 30px !important;
    }
    .row-2 {
      text-align: center !important;
      font-size: 30px !important;
      color: white;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 960px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      text-align: center !important;
      top: 5vh !important;
      padding-left: 10vw !important;
      // height: 10%;
      //right: 10vw;
      //top: 290px !important;

    }
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 30px !important;
    }
    .row-2 {
      text-align: center !important;
      font-size: 30px !important;
      color: white;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 768px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      text-align: center !important;
      top: 5vh !important;
      padding-left: 10vw !important;

    }
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 14px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 30px !important;
    }
    .row-2 {
      text-align: center !important;
      font-size: 20px !important;
      color: white;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 667px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      text-align: center !important;
      top: 5vh !important;
      padding-left: 10vw !important;
      padding-top: 10vh !important;
      // height: 10%;
      //right: 10vw;
      //top: 290px !important;

    }
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 30px !important;
    }
    .row-2 {
      text-align: center !important;
      font-size: 20px !important;
      color: white;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 600px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    .first-col {
      padding-top: 10vh !important;
      text-align: center !important;
      top: 5vh !important;
      padding-left: 10vw !important;
      // height: 10%;
      //right: 10vw;
      //top: 290px !important;

    }
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 30px !important;
      padding-bottom: 0vh !important;
    }
    .row-2 {
      text-align: center !important;
      font-size: 20px !important;
      color: white;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }
    .about-para {
      color: blue;
    }
  }
}

@media (max-width: 499px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    //.first-col {
    //  text-align: center !important;
    //  top: 5vh !important;
    //  padding-left: 10vw !important;
    //
    //}
    .second-col {
      height: 10%;
      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 18px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 24px !important;
      padding-bottom: 0vh !important;
    }
    .row-2 {
      text-align: center !important;
      font-size: 18px !important;
      line-height: 1rem !important;
      color: white;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }

  }
}

@media (max-width: 360px) {
  .about {
    padding-left: 0vw;
    padding-right: 0vw;
    .round-img {
      width: 210px !important;
      height: 210px !important;
    }
    //.first-col {
    //  text-align: center !important;
    //  top: 5vh !important;
    //  padding-left: 10vw !important;
    //
    //}
    .second-col {

      padding-left: 0vw !important;
      right: 0vw !important;
      top: 0vh !important;
    }
    .sp-number {
      font-size: 16px !important;
    }
    .name-row1 {
      text-align: center !important;
      font-size: 24px !important;
      padding-bottom: 0vh !important;
    }
    .row-2 {
      line-height: 1rem !important;
      font-size: 20px !important;
    }
    .name-row2 {
      text-align: center !important;
      font-size: 14px !important;
    }
    .class-section {
      padding-bottom: 1.2rem !important;
    }

  }
}
